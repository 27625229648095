const config = {
    api: {
      invokeUrl: process.env.REACT_APP_API_INVOKE_URL
    },
    aws_amplify: {
      Auth: {
        Region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
        UserPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
        UserPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID
      }
    }
  };
  
  export default config;