// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import config from "./config.js";
import reportWebVitals from './reportWebVitals';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.aws_amplify.Auth.Region,
    userPoolId: config.aws_amplify.Auth.UserPoolId,
    userPoolWebClientId: config.aws_amplify.Auth.UserPoolWebClientId
  }
});

const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter> {/* Wrap your App with BrowserRouter */}
    <App />
  </BrowserRouter>
);

reportWebVitals();
