import React, { useState } from 'react';
import { View, TextField, Button, useAuthenticator  } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './CustomSignIn.css';

const CustomSignIn = () => {
  const { toResetPassword } = useAuthenticator();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <View>
      <form>
        <TextField
          label="Username"
          placeholder="Enter your username"
          required
          name="username"
        />
        <div>
          <TextField
            label="Password"
            placeholder="Enter your password"
            type={showPassword ? 'text' : 'password'}
            required
            name="password"
          />
          <label>
            <input
              type="checkbox"
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
              className="amplify-checkbox"
            />
            <span>
              {showPassword ? 'Password is shown' : 'Password is hidden'}
            </span>
          </label>
        </div>
        <Button type="submit" className="amplify-button--primary">Sign In</Button>
        <button type="button" className="amplify-button--link" onClick={toResetPassword}>Forgot your password?</button>
      </form>
    </View>  
  );
};

export default CustomSignIn;