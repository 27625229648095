import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import AdminDashboard from './components/AdminDashboard';
import CustomSignIn from './components/CustomSignIn';

const App = () => {
  return (
    <Authenticator components={components} hideSignUp>
      {({ signOut, user }) => (
        <div>
          <Routes>
            <Route path="/" element={<AdminDashboard signOut={signOut} user={user} />} />
            <Route path="/" element={<CustomSignIn />} />
          </Routes>
        </div>
      )}
    </Authenticator>
  );
};
const components = {
  SignIn: {
    Header() {
      return (
        <div className="amplify-heading">
          <h1>Admin Portal</h1>
        </div>
      );
    }
  },
};
export default App;
